import Vue from "vue";
import Router from "vue-router";

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/overview",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/overview",
                    name: "overview",
                    meta: {
                        title: "用户总览",
                        icon: "fa fa-dashboard icon",
                        color: "bg-danger"
                    },
                    component: () => import("@/view/pages/Overview.vue")
                },
                {
                    path: "/details",
                    redirect: "/details/list",
                    name: "details",
                    meta: {
                        title: "用户详情",
                        icon: "fa fa-group icon",
                        color: "bg-success"
                    },
                    component: () => import("@/view/pages/Details.vue"),
                    children:[
                        {
                            path: "/details/list",
                            name: "details-list",
                            meta: {
                                title: "列表",
                                display: false
                            },
                            component: () => import("@/view/pages/DetailsList.vue")
                        },
                        {
                            path: "/details/info",
                            name: "details-info",
                            meta: {
                                title: "详情",
                                display: false
                            },
                            component: () => import("@/view/pages/DetailsInfo.vue")
                        }
                    ]
                },
                {
                    path: "/bills",
                    name: "bills",
                    meta: {
                        title: "用户通知",
                        icon: "fa fa-envelope-o icon",
                        color: "bg-warning"
                    },
                    component: () => import("@/view/pages/Bills.vue")
                },
                {
                    path: "/profile",
                    name: "profile",
                    meta: {
                        title: "账户设置",
                        display: false
                    },
                    component: () => import("@/view/pages/Profile.vue")
                },
                {
                    path: "/settings",
                    name: "settings",
                    meta: {
                        title: "偏好设置",
                        display: false
                    },
                    component: () => import("@/view/pages/Settings.vue")
                },
                {
                    path: "/memo",
                    name: "memo",
                    meta: {
                        title: "备忘录",
                        display: false
                    },
                    component: () => import("@/view/pages/Memo.vue")
                }
            ]
        },
        {
            path: "/login",
            name: "login",
            meta:{
                title: "用户登录",
            },
            component: () => import("@/view/pages/Login.vue")
        },
        {
            path: "/register",
            name: "register",
            meta:{
                title: "用户注册",
            },
            component: () => import("@/view/pages/Register.vue")
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            path: "/404",
            name: "404",
            meta:{
                title: "找不到页面",
            },
            component: () => import("@/view/error/404.vue")
        },
        {
            path: "/503",
            name: "503",
            meta:{
                title: "权限不足",
            },
            component: () => import("@/view/error/503.vue")
        }
    ]
});