import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/config/services/jwt.service";
import website from "@/config/website.json";

/**
 * 通过Axios调用HTTP请求的服务
 */
const ApiService = {

  /**
   * 初始化
   */
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = website.api;
  },

  /**
   * 设置默认HTTP请求标头
   */
  setHeader() {
    Vue.axios.defaults.headers.common["Authorization"] = `pc_${JwtService.getToken()}`;
  },

  /**
   * 发送GET HTTP请求
   * @param resource
   * @returns {*}
   */
  get(resource) {
    return Vue.axios.get(resource).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * 设置POST HTTP请求
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params,config={}) {
    return Vue.axios.post(`${resource}`, params,config);
  }

};

export default ApiService;
