/**
 * 设置KEY值和要查询的Value获取Object
 */
const setKeyFindObj = (array,key,value) => {
    //判断list是否是数组
    if(!(array instanceof Array)){
        return null
    }
    //遍历数组
    for(let i in array){
        let item=array[i]
        if (key){
            if(item[key]===value){
                return item
            }else{
                if(item.children){
                    let value = setKeyFindObj(item.children,value)
                    if(value) return value
                }
            }
        }else{
            if(item===value){
                return item
            }else{
                if(item.children){
                    let value = setKeyFindObj(item.children,value)
                    if(value) return value
                }
            }
        }
    }
}

/**
 * 菜单权限
 * @param asyncRoutes
 * @param menuList
 * @returns {*}
 */
const filterMenu = (asyncRoutes, menuList) => {
    return asyncRoutes.filter(item => {
        return menuList.indexOf(item.name) > -1
    }).map(subItem=> {
        subItem= Object.assign({}, subItem)
        if (subItem.children) {
            subItem.children = filterMenu(subItem.children, menuList)
        }
        return subItem
    })
}

/**
 * 菜单过滤 仅菜单使用
 * 删除Array中不需要的Object
 */
const menuFilter = (arr = []) => {
    return arr.filter(item => {
        return item.meta.display !== false
    }).map(item => {
        item = Object.assign({}, item)
        if (item.children) {
            item.children = menuFilter(item.children)
        }
        return item
    })
}

/**
 * 去除空数组
 * @param arr
 * @returns {*}
 */
const deleteChildren = (arr) => {
    let childs = arr
    for (let i = childs.length; i--; i > 0) {
        if (childs[i].children) {
            if (childs[i].children.length) {
                deleteChildren(childs[i].children)
            } else {
                delete childs[i].children
            }
        }
    }
    return arr
}

/**
 * 上传图像
 * 自动转base64编码
 */
const updateImage = (e) => {
    return new Promise(resolve => {
        const file = e.target.files[0];
        if (typeof FileReader === "function") {
            const reader = new FileReader();
            reader.onload = event => {
                resolve({code:200,status:'success',message:'上传成功',data:event.target.result});
            };
            reader.readAsDataURL(file);
        } else {
            resolve({code:403,status:'warning',message:'抱歉，不支持FileReader API',data:{}});
        }
    });
}

/**
 * 读取缓存
 * @param key
 * @returns {string|any}
 */
const readData = key => {
    if(!window.localStorage) return '';
    key = 'tf_' + key;
    return JSON.parse(localStorage.getItem(key));
}

/**
 * 设置缓存
 * @param key
 * @param data
 */
export const saveData = (key,data) => {
    key = 'tf_' + key;
    data = JSON.stringify(data);
    if (window.localStorage) {
        localStorage.setItem(key, data);
    }
};

/**
 * 文本框根据输入内容自适应高度
 * @param {HTMLElement} 输入框元素
 * @param {Number} 设置光标与输入框保持的距离(默认0)
 * @param {Number} 设置最大高度(可选)
 */
const autoTextarea = (elem, extra, maxHeight) => {
    extra = extra || 0;
    var isFirefox = !!document.getBoxObjectFor || 'mozInnerScreenX' in window,
        isOpera = !!window.opera && !!window.opera.toString().indexOf('Opera'),
        addEvent = function (type, callback) {
            elem.addEventListener ?
                elem.addEventListener(type, callback, false) :
                elem.attachEvent('on' + type, callback);
        },
        getStyle = elem.currentStyle ? function (name) {
            var val = elem.currentStyle[name];

            if (name === 'height' && val.search(/px/i) !== 1) {
                var rect = elem.getBoundingClientRect();
                return rect.bottom - rect.top -
                    parseFloat(getStyle('paddingTop')) -
                    parseFloat(getStyle('paddingBottom')) + 'px';
            }
            return val;
        } : function (name) {
            return getComputedStyle(elem, null)[name];
        },
        minHeight = parseFloat(getStyle('height'));
    elem.style.resize = 'none';
    var change = function () {
        var scrollTop, height,
            padding = 0,
            style = elem.style;
        if (elem._length === elem.value.length) return;
        elem._length = elem.value.length;

        if (!isFirefox && !isOpera) {
            padding = parseInt(getStyle('paddingTop')) + parseInt(getStyle('paddingBottom'));
        }
        scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        elem.style.height = minHeight + 'px';
        if (elem.scrollHeight > minHeight) {
            if (maxHeight && elem.scrollHeight > maxHeight) {
                height = maxHeight - padding;
                style.overflowY = 'auto';
            } else {
                height = elem.scrollHeight - padding;
                style.overflowY = 'hidden';
            }
            style.height = height + extra + 'px';
            scrollTop += parseInt(style.height) - elem.currHeight;
            document.body.scrollTop = scrollTop;
            document.documentElement.scrollTop = scrollTop;
            elem.currHeight = parseInt(style.height);
        }
    };
    addEvent('propertychange', change);
    addEvent('input', change);
    addEvent('focus', change);
    change();
}

/**
 * 获取分页页数
 * @param page
 * @param limit
 * @returns {number}
 */
const offsetCount = (page=0,limit=30) => {
    return (page-1)*limit;
}

/**
 * 和PHP一样的时间戳格式化函数
 * @param {string} format 格式
 * @param {int} timestamp 要格式化的时间 默认为当前时间
 * @return {string}   格式化的时间字符串
 */
const date = (format, timestamp) =>{
    let ret, jsdate=((timestamp) ? new Date(timestamp*1000) : new Date());
    let pad = function(n, c){
        if((n = n + "").length < c){
            return new Array(++c - n.length).join("0") + n;
        } else {
            return n;
        }
    };
    let txt_weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let txt_ordin = {1:"st", 2:"nd", 3:"rd", 21:"st", 22:"nd", 23:"rd", 31:"st"};
    let txt_months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let f = {
        // Day
        d: function(){return pad(f.j(), 2)},
        D: function(){return f.l().substr(0,3)},
        j: function(){return jsdate.getDate()},
        l: function(){return txt_weekdays[f.w()]},
        N: function(){return f.w() + 1},
        S: function(){return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th'},
        w: function(){return jsdate.getDay()},
        z: function(){return (jsdate - new Date(jsdate.getFullYear() + "/1/1")) / 864e5 >> 0},

        // Week
        W: function(){
            let a = f.z(), b = 364 + f.L() - a;
            let nd2, nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;
            if(b <= 2 && ((jsdate.getDay() || 7) - 1) <= 2 - b){
                return 1;
            } else{
                if(a <= 2 && nd >= 4 && a >= (6 - nd)){
                    nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
                    return date("W", Math.round(nd2.getTime()/1000));
                } else{
                    return (1 + (nd <= 3 ? ((a + nd) / 7) : (a - (7 - nd)) / 7) >> 0);
                }
            }
        },

        // Month
        F: function(){return txt_months[f.n()]},
        m: function(){return pad(f.n(), 2)},
        M: function(){return f.F().substr(0,3)},
        n: function(){return jsdate.getMonth() + 1},
        t: function(){
            let n;
            if( (n = jsdate.getMonth() + 1) == 2 ){
                return 28 + f.L();
            } else{
                if( n & 1 && n < 8 || !(n & 1) && n > 7 ){
                    return 31;
                } else{
                    return 30;
                }
            }
        },

        // Year
        L: function(){let y = f.Y();return (!(y & 3) && (y % 1e2 || !(y % 4e2))) ? 1 : 0},
        //o not supported yet
        Y: function(){return jsdate.getFullYear()},
        y: function(){return (jsdate.getFullYear() + "").slice(2)},

        // Time
        a: function(){return jsdate.getHours() > 11 ? "pm" : "am"},
        A: function(){return f.a().toUpperCase()},
        B: function(){
            // peter paul koch:
            let off = (jsdate.getTimezoneOffset() + 60)*60;
            let theSeconds = (jsdate.getHours() * 3600) + (jsdate.getMinutes() * 60) + jsdate.getSeconds() + off;
            let beat = Math.floor(theSeconds/86.4);
            if (beat > 1000) beat -= 1000;
            if (beat < 0) beat += 1000;
            if ((String(beat)).length == 1) beat = "00"+beat;
            if ((String(beat)).length == 2) beat = "0"+beat;
            return beat;
        },
        g: function(){return jsdate.getHours() % 12 || 12},
        G: function(){return jsdate.getHours()},
        h: function(){return pad(f.g(), 2)},
        H: function(){return pad(jsdate.getHours(), 2)},
        i: function(){return pad(jsdate.getMinutes(), 2)},
        s: function(){return pad(jsdate.getSeconds(), 2)},
        //u not supported yet

        // Timezone
        //e not supported yet
        //I not supported yet
        O: function(){
            let t = pad(Math.abs(jsdate.getTimezoneOffset()/60*100), 4);
            if (jsdate.getTimezoneOffset() > 0) t = "-" + t; else t = "+" + t;
            return t;
        },
        P: function(){let O = f.O();return (O.substr(0, 3) + ":" + O.substr(3, 2))},
        //T not supported yet
        //Z not supported yet

        // Full Date/Time
        c: function(){return f.Y() + "-" + f.m() + "-" + f.d() + "T" + f.h() + ":" + f.i() + ":" + f.s() + f.P()},
        //r not supported yet
        U: function(){return Math.round(jsdate.getTime()/1000)}
    };

    return format.replace(/([a-zA-Z])/g, function(t, s){
        if( t!=s ){
            // escaped
            ret = s;
        } else if( f[s] ){
            // a date function exists
            ret = f[s]();
        } else{
            // nothing special
            ret = s;
        }
        return ret;
    });
}

/**
 * 金额过滤
 * @param num
 * @returns {string}
 * @constructor
 */
export const ThousandAndDecimal = (num) => {
    if(num) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    } else {
        return '0.00'
    }
}

export default {setKeyFindObj,filterMenu,menuFilter,deleteChildren,updateImage,readData,saveData,autoTextarea,offsetCount,date,ThousandAndDecimal};