import ApiService from "@/config/services/api.service";

export const SET_TITLE = "setTitle";
export const UPDATE_CONFIG = "updateConfig";
export const SET_CONFIG = "setConfig";
export const CONFIG = "config";

const state = {
    web_title:'',
    config:{
        web_logo:'',
        web_name:'',
        web_domain:'',
        web_keywords:'',
        web_description:'',
        system_time:''
    }
}

const actions = {
    async [UPDATE_CONFIG](context,payload) {
        await ApiService.get('/frame/config').then(({ data }) => {
            if (data.code===200){
                context.commit(SET_CONFIG,data['data']);
                context.commit(SET_TITLE,payload.title?payload.title:'');
            }
        });
    },
    [CONFIG](context,payload) {
        return new Promise(resolve => {
            ApiService.post('/frame/config/set',payload).then(({ data }) => {
                if (data.code===200){
                    context.commit(SET_CONFIG,payload);
                    context.commit(SET_TITLE,state.web_title?state.web_title:'');
                }
                resolve(data);
            });
        });
    }
}

const mutations = {
    [SET_TITLE](state, title) {
        document.title = title + ' - ' + state.config.web_name;
        state.web_title = title;
    },
    [SET_CONFIG](state, config) {
        state.config = {
            web_logo:config.web_logo,
            web_name:config.web_name,
            web_domain:config.web_domain,
            web_keywords:config.web_keywords,
            web_description:config.web_description,
            system_time:config.system_time
        };
    }
}

const getters = {
    currentTitle(state){
        return state.web_title;
    },
    currentConfig(state){
        return state.config;
    }
}

export default {state,actions,mutations,getters}