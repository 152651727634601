export const UPDATE_LIST = "updateList";
export const SET_LIST = "setList";

const state = {
    list:[]
}

const actions = {
    [UPDATE_LIST](context, payload) {
        let list = [];
        if (payload.length>0){
            for (let i = 0; i<payload.length;i++){
                list.push({'name':payload[i].name,'redirect':payload[i].redirect,'title':payload[i].meta.title?payload[i].meta.title:''})
            }
        }
        context.commit(SET_LIST,list);
    }
}

const mutations = {
    [SET_LIST](state, list) {
        state.list = list;
    }
}

const getters = {
    currentBreadcrumbList(state){
        return state.list;
    }
}

export default {state,actions,mutations,getters}