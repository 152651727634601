import JwtService from "@/config/services/jwt.service";

export const THEME = "theme";
export const SET_THEME = "setTheme";

const state = {
    theme:{
        systemHeaderLeftTopProject:JwtService.getTheme()?JwtService.getTheme()['systemHeaderLeftTopProject']!==''?JwtService.getTheme()['systemHeaderLeftTopProject']:true:true,
        systemTopNotice:JwtService.getTheme()?JwtService.getTheme()['systemTopNotice']!==''?JwtService.getTheme()['systemTopNotice']:true:true,
        systemTopSearch:JwtService.getTheme()?JwtService.getTheme()['systemTopSearch']!==''?JwtService.getTheme()['systemTopSearch']:true:true,
        systemLeftNavbar:JwtService.getTheme()?JwtService.getTheme()['systemLeftNavbar']!==''?JwtService.getTheme()['systemLeftNavbar']:'white':'white'
    }
}

const actions = {
    [THEME](context, credentials){
        return new Promise(resolve => {
            context.commit(SET_THEME,credentials);
            JwtService.saveTheme(state.theme);
            resolve()
        });
    }
}

const mutations = {
    [SET_THEME](state,credentials){
        state.theme[credentials.key] = credentials.value;
    }
}

const getters = {
    getThemes(state){
        return state.theme;
    }
}

export default {state,actions,mutations,getters}