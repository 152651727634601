import Vue from "vue";
import Vuex from "vuex";

import auth from "@/config/store/auth";
import web from "@/config/store/web";
import breadcrumb from "@/config/store/breadcrumb";
import settings from "@/config/store/settings";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        web,
        breadcrumb,
        settings
    }
})