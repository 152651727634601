import ApiService from "@/config/services/api.service";
import JwtService from "@/config/services/jwt.service";
import tool from "@/config/tool/arabina";

export const LOGIN = "login";
export const REGISTER = "register";
export const VERIFY_AUTH = "verifyAuth";
export const LOGOUT = "logout";
export const SET_AUTH = "setAuth";
export const PURGE_AUTH = "logOut";
export const UPDATE_ACCOUNT_INFO = "updateAccountInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_MENU = "setMenu";

const state = {
    isAuthenticated: !!JwtService.getToken(),
    account_info:{
        portrait:'',
        nickname:'',
        username:''
    },
    menu:[]
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise(resolve => {
            ApiService.post("/frame/user/login", credentials).then(({ data }) => {
                if (data.code===200){
                    JwtService.saveToken(data['data']['token']);
                    context.commit(SET_AUTH);
                    context.commit(SET_ACCOUNT_INFO,data['data'])
                    context.commit(SET_MENU,data['data'].memu)
                }
                resolve(data);
            }).catch(({ response }) => {
                console.log(response)
                context.commit(PURGE_AUTH);
            });
        });
    },
    [REGISTER](context, credentials) {
        return new Promise(resolve => {
            ApiService.post("/frame/user/register", credentials).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                console.log(response)
                context.commit(PURGE_AUTH);
            });
        });
    },
    async [VERIFY_AUTH](context) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            await ApiService.get("/frame/user/verify").then(({ data }) => {
                if (data.code===200){
                    context.commit(SET_AUTH);
                    context.commit(SET_ACCOUNT_INFO,data['data'])
                    context.commit(SET_MENU,data['data'].memu)
                }else{
                    context.commit(PURGE_AUTH);
                }
            }).catch(({ response }) => {
                console.log(response)
                context.commit(PURGE_AUTH);
            });
        } else {
            context.commit(PURGE_AUTH);
        }
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH);
    },
    [UPDATE_ACCOUNT_INFO](context, payload) {
        return new Promise(resolve => {
            switch (payload.mode) {
                case 'portrait':
                    tool.updateImage(payload.e).then(data => {
                        if (data.code===200){
                            let postData = {
                                mode:'portrait',
                                portrait:data['data']
                            }
                            context.commit(SET_ACCOUNT_INFO,{
                                nickname:state.account_info.nickname,
                                username:state.account_info.username,
                                portrait:data['data']?data['data']:state.account_info.portrait
                            })
                            ApiService.post("/frame/user/profile",postData);
                        }
                        resolve(data);
                    });
                    break;
                case 'profile':
                    ApiService.post("/frame/user/profile",payload).then(({ data }) => {
                        if (data.code===200){
                            context.commit(SET_ACCOUNT_INFO, {
                                nickname:payload.nickname?payload.nickname:state.account_info.nickname,
                                username:state.account_info.username,
                                portrait:payload.portrait?payload.portrait:state.account_info.portrait
                            });
                        }
                        resolve(data);
                    });
                    break;
                case 'password':
                    ApiService.post("/frame/user/profile",payload).then(({ data }) => {
                        if (data.code===200){
                            context.commit(PURGE_AUTH);
                        }
                        resolve(data);
                    });
                    break;
                default:
                    resolve({code:403,status:'success',message:'未知操作',data:[]});
                    break;
            }
        });
    }
}

const mutations = {
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        JwtService.destroyToken();
    },
    [SET_AUTH](state) {
        state.isAuthenticated = true;
    },
    [SET_ACCOUNT_INFO](state, user_account_info) {
        state.account_info = user_account_info;
    },
    [SET_MENU](state, menu) {
        state.menu = menu;
    }
}

const getters = {
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    currentAccountInfo(state) {
        return state.account_info;
    },
    currentUserMenu(state){
        return state.menu;
    }
};

export default {state,actions,mutations,getters}