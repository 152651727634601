const ID_TOKEN_KEY = "token";
const ID_THEME_KEY = "theme";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getTheme = () => {
  if(!window.localStorage) return '';
  return JSON.parse(localStorage.getItem(ID_THEME_KEY));
}

export const saveTheme = (theme) => {
  theme = JSON.stringify(theme);
  if (window.localStorage) {
    localStorage.setItem(ID_THEME_KEY, theme);
  }
};

export const destroyTheme = () => {
  window.localStorage.removeItem(ID_THEME_KEY);
};

export default { getToken, saveToken, destroyToken, getTheme, saveTheme, destroyTheme };