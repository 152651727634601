import Vue from 'vue'
import App from './App.vue'
import router from "./config/router"
import ApiService from "@/config/services/api.service"
import store from "@/config/store"
import { VERIFY_AUTH } from "@/config/store/auth"
import {UPDATE_CONFIG} from "@/config/store/web"
import {UPDATE_LIST} from "@/config/store/breadcrumb"
import $ from "jquery"
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import ElementUI from 'element-ui'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import 'element-ui/lib/theme-chalk/index.css'
import tool from "@/config/tool/arabina"
import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

ApiService.init();

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  Promise.all([await store.dispatch(VERIFY_AUTH), store.dispatch(UPDATE_CONFIG,to.meta),store.dispatch(UPDATE_LIST,to.matched)]).then(() =>{
    if (to.name!=='404') if (store.getters.isAuthenticated) if (store.getters.currentUserMenu.length>0 && tool.setKeyFindObj(store.getters.currentUserMenu,'',to.name)===undefined && to.name!=='503') next({name:'503'});
    next();
  });
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach(() => {
  NProgress.done();
});

window.jQuery = $;
window.$ = $;

Vue.use(ElementUI,{zhLocale});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')